import React, { useState } from "react";
import styles from "./HeroSection.module.scss";
import graphImage from "./assets/img/graph.png";
import ratingImage from "./assets/img/rating.png";
import linesImage from "./assets/img/lines.png";
import Modal from "../common/Modal";
import WaitlistForm from "../WaitlistForm/WaitlistForm";

const HeroSection: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={styles.innerCard}>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>
          <img src={linesImage} className={styles.linesImage} />
          Streamlining <i className={styles.hirelystSpan}>hiring</i>
          <br></br>
          for cybersecurity engineers!
        </h1>
        <p className={styles.description}>
          Hirelyst simplifies cybersecurity hiring through CTF-based candidate
          assessments and hackathons, helping you identify top talent with
          real-world challenges
        </p>
        <div className={styles.buttonContainer}>
          <button onClick={openModal} className={styles.demoButton}>
            Book a Demo <span className={styles.arrow}>→</span>
          </button>
        </div>
        <div className={styles.imageContainer}>
          <img src={ratingImage} className={styles.ratingImage} />
        </div>
      </div>
      {/* <img src={incomeImage} className={styles.incomeImage} />
        <img src={recordImage} className={styles.recordImage} /> */}
      <img src={graphImage} className={styles.graphImage} />
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <WaitlistForm />
      </Modal>
    </div>
  );
};

export default HeroSection;
