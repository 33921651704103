import React, { useState } from "react";
import ExpandableCard from "../common/ExpandableCard";
import styles from "./FeatureSection.module.scss";
import laptopImage from "./assets/img/laptopImage.png";
import Modal from "../common/Modal";
import WaitlistForm from "../WaitlistForm/WaitlistForm";

const expandableData = [
  {
    title: "CTF Hiring",
    content:
      "Evaluate individual candidates with real-world Capture-The-Flag challenges. Simulate real-world scenarios to test technical expertise, critical thinking, and problem-solving abilities. Get detailed performance analytics to make confident hiring decisions.",
  },
  {
    title: "Hackathon Hiring",
    content:
      "Host competitive, solo hackathons to identify high-performing candidates. Challenge participants with engaging problems that assess their creativity and technical expertise. Build a pipeline of skilled, job-ready cybersecurity professionals.",
  },
  // {
  //   title: "Cybersecurity Questions Library",
  //   content:
  //     "Access a comprehensive library of questions tailored to assess cybersecurity knowledge in various domains.",
  // },
  // {
  //   title: "Candidate Assessment Reports",
  //   content:
  //     "Get detailed assessment reports, helping you make informed hiring decisions with real-time candidate performance insights.",
  // },
];

const FeatureSection: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const handleCardClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className={styles.outerCard}>
      <div className={styles.contentContainer}>
        <div>
          <h1 className={styles.title}>
            Our Offering: CTF Assessments & Hackathons!
          </h1>
          <p className={styles.description}>
            Leverage real-world challenges to evaluate technical expertise and
            problem-solving skills. Our tailored CTFs and hackathons provide
            actionable insights to identify the best cybersecurity
            professionals.
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={openModal} className={styles.demoButton}>
            Book a Demo <span className={styles.arrow}>→</span>
          </button>
        </div>
      </div>
      <div className={styles.innerCard}>
        <img
          src={laptopImage}
          alt="Laptop showing graph"
          className={styles.laptopImage}
        />
        <div className={styles.expandableDiv}>
          {expandableData.map((card, index) => (
            <ExpandableCard
              key={card.title}
              title={card.title}
              // isOpen={openIndex === index}
              isOpen={true}
              onClick={() => handleCardClick(index)}
            >
              {card.content}
            </ExpandableCard>
          ))}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <WaitlistForm />
      </Modal>
    </div>
  );
};

export default FeatureSection;
